COOKE = {

  common : {
    init : function() {
      // this.initMobileNav();
      this.initSliderNav();
      this.initModals();
    },

    initSliderNav: function() {
      $('.slider-nav').slick({
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        asNavFor: '.slider-image',
        focusOnSelect: true
      });

      $('.featured-property-photo-slider').slick({
        infinite: true,
        slidesToShow: 7,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        asNavFor: '.slider-image',
        focusOnSelect: true
      });

      $('.slider-image').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        arrows: false,
        asNavFor: '.slider-nav'
      });

      $('.home-slider-image').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000
      });
    },

    initModals : function() {
      $('.modal-link').modaal();
      
      $('.map-modal-link').modaal({
        after_open: function() {
          map.resize();
        }
      });
    }
  }

}



/* via http://paulirish.com/2009/markup-based-unobtrusive-comprehensive-dom-ready-execution/ */
UTIL = {
 
  fire : function(func,funcname, args){ 
    var namespace = COOKE;  // indicate your obj literal namespace here 
    funcname = (funcname === undefined) ? 'init' : funcname;
    if (func !== '' && namespace[func] && typeof namespace[func][funcname] == 'function'){
      namespace[func][funcname](args);
    }  
  }, 
 
  loadEvents : function(){
    var bodyId = document.body.id;
 
    // hit up common first.
    UTIL.fire('common');
 
    // do all the classes too.
    $.each(document.body.className.split(/\s+/),function(i,classnm){
      UTIL.fire(classnm);
      UTIL.fire(classnm,bodyId);
    }); 
    UTIL.fire('common','finalize'); 
  } 
 
}; 
 
// kick it all off here 
$(document).ready(UTIL.loadEvents);